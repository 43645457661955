.transition{
    // display: none;
    width: 100%;
    transition: all 0.4s;
  }
.form{
  width: 100%;
  transition: width 0.4s;
  overflow: hidden;
}
.btn{
    width: 100%;
}
@primary-color: #3F51B5;