/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.cancel {
  margin-left: 1em;
}
.inlineForm {
  display: flex;
  flex-wrap: wrap;
}
.btns {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 1em;
  margin-left: 1em;
}
.inlineForm {
  display: flex;
  flex-wrap: wrap;
}
.btns {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 1em;
}
