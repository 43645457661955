/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.container > * {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.flex {
  display: flex;
  align-items: center;
}
.helpIcon {
  color: 'white';
  font-size: 18px;
  cursor: pointer !important;
}
@media screen and (max-width: 480px) {
  .container {
    width: 100% !important;
  }
  .container > * {
    border-radius: 0 !important;
  }
}
