/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url('https://ishaan-amcu.s3-us-west-2.amazonaws.com/static/background-amcu.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.lang {
  text-align: right;
  width: 100%;
  height: 40px;
  line-height: 44px;
}
.lang :global(.ant-dropdown-trigger) {
  margin-right: 24px;
}
.content {
  padding: 32px 0;
  flex: 1;
  z-index: 1;
  overflow-y: auto;
}
@media (min-width: 768px) {
  .container {
    background-image: url('https://ishaan-amcu.s3-us-west-2.amazonaws.com/static/background-amcu.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .content {
    padding: 32px 0 24px 0;
  }
}
.top {
  text-align: center;
}
.header {
  height: 44px;
  line-height: 44px;
}
.header a {
  text-decoration: none;
}
.logo {
  margin: 0px auto 30px;
  text-align: center;
}
.title {
  font-size: 33px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
}
.desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}
.cover {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: flex-end;
}
.cover img {
  width: 100%;
}
.cover .left {
  width: 70%;
}
.cover .right {
  width: 30%;
}
@media screen and (max-width: 576px) {
  .cover .left {
    width: 0%;
  }
  .cover .right {
    width: 100%;
    margin: 0 30%;
  }
}
.main {
  background-color: white;
  border-radius: 12px;
  padding: 50px;
  padding-top: 20px;
  max-width: 468px;
  margin: 40px auto;
}
@media screen and (max-width: 576px) {
  .main {
    width: 95%;
    padding: 20px;
  }
}
