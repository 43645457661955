/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.icon:hover {
  color: #3F51B5;
}
.other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.other .register {
  float: right;
}
.forgot {
  float: right;
}
.button {
  width: 100%;
}
.register {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}
.transition {
  display: none;
  width: 100%;
  transition: all 0.4s;
}
.form {
  width: 100%;
  transition: width 0.4s;
  overflow: hidden;
}
.transition1 {
  width: 100%;
  transition: all 0.4s;
}
.form1 {
  width: 100%;
  transition: width 0.4s;
  overflow: hidden;
}
.btn {
  width: 100%;
}
.deleteAccountForm {
  margin-bottom: 12px;
}
