/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-layouts-auth-layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url('https://ishaan-amcu.s3-us-west-2.amazonaws.com/static/background-amcu.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.antd-pro-layouts-auth-layout-lang {
  text-align: right;
  width: 100%;
  height: 40px;
  line-height: 44px;
}
.antd-pro-layouts-auth-layout-lang .ant-dropdown-trigger {
  margin-right: 24px;
}
.antd-pro-layouts-auth-layout-content {
  padding: 32px 0;
  flex: 1 1;
  z-index: 1;
  overflow-y: auto;
}
@media (min-width: 768px) {
  .antd-pro-layouts-auth-layout-container {
    background-image: url('https://ishaan-amcu.s3-us-west-2.amazonaws.com/static/background-amcu.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .antd-pro-layouts-auth-layout-content {
    padding: 32px 0 24px 0;
  }
}
.antd-pro-layouts-auth-layout-top {
  text-align: center;
}
.antd-pro-layouts-auth-layout-header {
  height: 44px;
  line-height: 44px;
}
.antd-pro-layouts-auth-layout-header a {
  text-decoration: none;
}
.antd-pro-layouts-auth-layout-logo {
  margin: 0px auto 30px;
  text-align: center;
}
.antd-pro-layouts-auth-layout-title {
  font-size: 33px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
}
.antd-pro-layouts-auth-layout-desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}
.antd-pro-layouts-auth-layout-cover {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: flex-end;
}
.antd-pro-layouts-auth-layout-cover img {
  width: 100%;
}
.antd-pro-layouts-auth-layout-cover .antd-pro-layouts-auth-layout-left {
  width: 70%;
}
.antd-pro-layouts-auth-layout-cover .antd-pro-layouts-auth-layout-right {
  width: 30%;
}
@media screen and (max-width: 576px) {
  .antd-pro-layouts-auth-layout-cover .antd-pro-layouts-auth-layout-left {
    width: 0%;
  }
  .antd-pro-layouts-auth-layout-cover .antd-pro-layouts-auth-layout-right {
    width: 100%;
    margin: 0 30%;
  }
}
.antd-pro-layouts-auth-layout-main {
  background-color: white;
  border-radius: 12px;
  padding: 50px;
  padding-top: 20px;
  max-width: 468px;
  margin: 40px auto;
}
@media screen and (max-width: 576px) {
  .antd-pro-layouts-auth-layout-main {
    width: 95%;
    padding: 20px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-global-footer-index-globalFooter {
  padding: 0 16px;
  margin: 48px 0 24px 0;
  text-align: center;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links {
  margin-bottom: 8px;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a:not(:last-child) {
  margin-right: 40px;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
h2 {
  color: #3F51B5;
  font-size: 18px;
  padding: 0;
  margin-bottom: 3px;
  margin-top: 6px;
  margin-left: 10px;
}
.antd-pro-elements-dashboard-views-styles-wrapper {
  display: flex;
  flex-direction: row;
  padding: 0em;
  height: calc(100vh - 112px);
}
.antd-pro-elements-dashboard-views-styles-leftContent {
  flex: 1 1;
  margin-left: 2em;
}
.antd-pro-elements-dashboard-views-styles-liveCollection {
  display: flex;
  flex: 7 1;
  flex-direction: column;
  overflow: scroll;
  background: white;
  border-radius: 4px;
}
.antd-pro-elements-dashboard-views-styles-liveCollection h2 {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
.antd-pro-elements-dashboard-views-styles-childData {
  font-size: 10px;
}
.antd-pro-elements-dashboard-views-styles-compare {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  background: white;
  border-radius: 4px;
  margin: 10px 0;
  padding: 10px 0 0 10px;
}
.antd-pro-elements-dashboard-views-styles-secondCompare {
  width: 50%;
}
.antd-pro-elements-dashboard-views-styles-compareChildren {
  border: 1px solid rgba(139, 137, 137, 0.404);
  border-radius: 5px;
  display: flex;
  height: 70px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.antd-pro-elements-dashboard-views-styles-heading {
  background-color: #3F51B5;
  border-radius: 3px;
  padding: 0.4em 0.4em 0.4em 0.5em;
  font-size: 1.3em;
  font-weight: 400;
  color: white;
}
.antd-pro-elements-dashboard-views-styles-headingDummy {
  background-color: #3F51B5;
  text-align: center;
  border-radius: 3px;
  padding: 0.4em 0.4em 0.4em 0.5em;
  font-size: 1.3em;
  font-weight: 400;
  color: white;
}
.antd-pro-elements-dashboard-views-styles-invertHeading {
  background-color: white;
  border-radius: 3px;
  padding: 0.4em 0.4em 0.4em 0.5em;
  font-size: 1.3em;
  font-weight: 600;
  color: #3F51B5;
}
.antd-pro-elements-dashboard-views-styles-tableWrapper {
  background-color: white;
  margin-top: 0.5em;
  border-radius: 5px;
  overflow: hidden;
}
.antd-pro-elements-dashboard-views-styles-parent {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}
.antd-pro-elements-dashboard-views-styles-rightContent {
  flex: 1 1;
  margin-left: 2em;
  max-width: 300px !important;
}
.antd-pro-elements-dashboard-views-styles-headData {
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}
.antd-pro-elements-dashboard-views-styles-headDate {
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  font-size: large;
}
.antd-pro-elements-dashboard-views-styles-labelSuccess {
  background-color: #5cb85c;
}
.antd-pro-elements-dashboard-views-styles-labelInfo {
  background-color: #f0ad4e;
}
.antd-pro-elements-dashboard-views-styles-table {
  text-align: right;
  width: 100%;
}
.antd-pro-elements-dashboard-views-styles-table th {
  background-color: rgba(128, 128, 128, 0.109);
  border: 1px solid rgba(139, 137, 137, 0.404);
  padding: 0.3em 1em 0.3em 0;
}
.antd-pro-elements-dashboard-views-styles-table td {
  border: 1px solid rgba(139, 137, 137, 0.404);
  padding: 0.3em 1em 0.3em 0;
}
.antd-pro-elements-dashboard-views-styles-table tr td:nth-child(1) {
  font-size: 12px;
}
.antd-pro-elements-dashboard-views-styles-table tr:nth-child(2) td:nth-child(1) {
  font-weight: 500;
  color: #ec0da7;
}
.antd-pro-elements-dashboard-views-styles-table tr:nth-child(3) td:nth-child(1) {
  font-weight: 500;
  color: #06CE4A;
}
.antd-pro-elements-dashboard-views-styles-table tr:nth-child(4) td:nth-child(1) {
  font-weight: 500;
  color: #BE5FC7;
}
.antd-pro-elements-dashboard-views-styles-table tr:nth-child(5) td:nth-child(1) {
  font-weight: 500;
  color: #FFBB0E;
}
.antd-pro-elements-dashboard-views-styles-table tr:nth-child(6) td:nth-child(1) {
  font-weight: 500;
  color: #0D9CEC;
}
.antd-pro-elements-dashboard-views-styles-childValue {
  font-size: 1em;
  font-weight: bold;
}
.antd-pro-elements-dashboard-views-styles-childSubValue {
  font-weight: 600;
  font-size: 1.5em;
}
.antd-pro-elements-dashboard-views-styles-compareChild {
  padding: 10px;
  width: 60%;
  color: white;
}
.antd-pro-elements-dashboard-views-styles-accepted,
.antd-pro-elements-dashboard-views-styles-rejected {
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  margin: 0 10px;
}
.antd-pro-elements-dashboard-views-styles-accepted {
  color: #8BC34A;
  border: 1px #8BC34A solid;
  background: #F6FFED;
}
.antd-pro-elements-dashboard-views-styles-rejected {
  color: #E21C3D;
  border: 1px #E21C3D solid;
  background: #F9DEDE;
}
.antd-pro-elements-dashboard-views-styles-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.antd-pro-elements-dashboard-views-styles-ticker {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  font-size: 14px;
}
.antd-pro-elements-dashboard-views-styles-collectionDate {
  padding: 8px;
  text-align: end;
}
@media screen and (max-width: 480px) {
  .antd-pro-elements-dashboard-views-styles-wrapper {
    height: inherit;
  }
  .antd-pro-elements-dashboard-views-styles-rightContent {
    flex: 1 1;
    max-width: none !important;
    max-width: initial !important;
    margin: 0;
  }
  .antd-pro-elements-dashboard-views-styles-leftContent {
    display: none;
  }
}
.antd-pro-elements-dashboard-views-styles-date {
  margin-left: 25em;
  font-size: 0.8em;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-exception-index-exception {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:before,
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:after {
  content: ' ';
  display: table;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgEle {
  height: 360px;
  width: 100%;
  max-width: 430px;
  float: right;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content {
  flex: auto;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content .antd-pro-components-exception-index-desc {
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content .antd-pro-components-exception-index-actions button:not(:last-child) {
  margin-right: 8px;
}
@media screen and (max-width: 1200px) {
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    padding-right: 88px;
  }
}
@media screen and (max-width: 576px) {
  .antd-pro-components-exception-index-exception {
    display: block;
    text-align: center;
  }
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    padding-right: 0;
    margin: 0 auto 24px;
  }
}
@media screen and (max-width: 480px) {
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    margin-bottom: -24px;
    overflow: hidden;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-global-header-index-header {
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
  display: flex;
  justify-content: space-between;
}
.antd-pro-components-global-header-index-mobileHeader {
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
  display: flex;
  justify-content: space-between;
}
.antd-pro-components-global-header-index-logo {
  height: 64px;
  line-height: 64px;
  vertical-align: top;
  display: inline-block;
  padding: 0 0 0 24px;
  cursor: pointer;
  font-size: 20px;
}
.antd-pro-components-global-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
}
.antd-pro-components-global-header-index-menu .anticon {
  margin-right: 8px;
}
.antd-pro-components-global-header-index-menu .ant-dropdown-menu-item {
  min-width: 160px;
}
.antd-pro-components-global-header-index-trigger {
  font-size: 20px;
  height: 64px;
  line-height: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s, padding 0s;
  transition: all 0.3s, padding 0s;
  padding: calc((64px - 20px) / 2) 24px;
}
.antd-pro-components-global-header-index-trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right {
  float: right;
  height: 100%;
  overflow: hidden;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action {
  cursor: pointer;
  padding: 0 12px;
  display: flex;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 100%;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action > i {
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action.opened {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search {
  padding: 0 12px;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search:hover {
  background: transparent;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
  margin: calc((64px - 24px) / 2) 0;
  margin-right: 8px;
  color: #3F51B5;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: top;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-name {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.antd-pro-components-global-header-index-searchIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.antd-pro-components-global-header-index-searchIcon {
  color: white;
  font-size: 20px;
}
.antd-pro-components-global-header-index-searchIcon:hover {
  color: lightgray;
}
@media screen and (max-width: 480px) {
  .antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-name {
    color: black;
  }
}
.antd-pro-components-global-header-index-dark {
  height: 64px;
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action > i {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action:hover,
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action.opened {
  background: #3F51B5;
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action .ant-badge {
  color: rgba(255, 255, 255, 0.85);
}
@media only screen and (max-width: 768px) {
  .antd-pro-components-global-header-index-header .ant-divider-vertical {
    vertical-align: unset;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-name {
    display: none;
  }
  .antd-pro-components-global-header-index-header i.antd-pro-components-global-header-index-trigger {
    padding: 22px 12px;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-logo {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-right {
    position: absolute;
    right: 12px;
    top: 0;
    background: #fff;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
    margin-right: 0;
  }
}
.antd-pro-components-global-header-index-company {
  display: inline;
  font-weight: bold;
  font-size: medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-header-dropdown-index-container > * {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.antd-pro-components-header-dropdown-index-flex {
  display: flex;
  align-items: center;
}
.antd-pro-components-header-dropdown-index-helpIcon {
  color: 'white';
  font-size: 18px;
  cursor: pointer !important;
}
@media screen and (max-width: 480px) {
  .antd-pro-components-header-dropdown-index-container {
    width: 100% !important;
  }
  .antd-pro-components-header-dropdown-index-container > * {
    border-radius: 0 !important;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-notice-icon-notice-list-list {
  max-height: 400px;
  overflow: auto;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-meta {
  width: 100%;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-avatar {
  background: #fff;
  margin-top: 4px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-iconElement {
  font-size: 32px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item.antd-pro-components-notice-icon-notice-list-read {
  opacity: 0.4;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:last-child {
  border-bottom: 0;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:hover {
  background: #e6ebf5;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-title {
  font-weight: normal;
  margin-bottom: 8px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-description {
  font-size: 12px;
  line-height: 1.5;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-datetime {
  font-size: 12px;
  margin-top: 4px;
  line-height: 1.5;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-extra {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  margin-right: 0;
  margin-top: -1.5px;
}
.antd-pro-components-notice-icon-notice-list-notFound {
  text-align: center;
  padding: 73px 0 88px 0;
  color: rgba(0, 0, 0, 0.45);
}
.antd-pro-components-notice-icon-notice-list-notFound img {
  display: inline-block;
  margin-bottom: 16px;
  height: 76px;
}
.antd-pro-components-notice-icon-notice-list-clear {
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #e8e8e8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}
.antd-pro-components-notice-icon-notice-list-clear:hover {
  color: rgba(0, 0, 0, 0.85);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-notice-icon-index-popover {
  width: 336px;
}
.antd-pro-components-notice-icon-index-noticeButton {
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.antd-pro-components-notice-icon-index-icon {
  padding: 4px;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
  text-align: center;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-bar {
  margin-bottom: 4px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-header-search-index-headerSearch .anticon-search {
  cursor: pointer;
  font-size: 16px;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input {
  -webkit-transition: width 0.3s, margin-left 0.3s;
  transition: width 0.3s, margin-left 0.3s;
  width: 0;
  background: transparent;
  border-radius: 0;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input .ant-select-selection {
  background: transparent;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input input {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none !important;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input,
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input:hover,
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input:focus {
  border-bottom: 1px solid #d9d9d9;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input.antd-pro-components-header-search-index-show {
  width: 210px;
  margin-left: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-sider-menu-index-logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #002140;
  overflow: hidden;
}
.antd-pro-components-sider-menu-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.antd-pro-components-sider-menu-index-logo h1 {
  color: white;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  margin: 0 0 0 12px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.antd-pro-components-sider-menu-index-sider {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-fixSiderbar {
  position: fixed;
  top: 0;
  left: 0;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-fixSiderbar .ant-menu-root {
  overflow-y: auto;
  height: calc(100vh - 64px);
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  background-color: white;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .antd-pro-components-sider-menu-index-logo {
  background: white;
  box-shadow: 1px 1px 0 0 #e8e8e8;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .antd-pro-components-sider-menu-index-logo h1 {
  color: #3F51B5;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .ant-menu-light {
  border-right-color: transparent;
}
.antd-pro-components-sider-menu-index-icon {
  width: 14px;
  margin-right: 10px;
}
.top-nav-menu li.ant-menu-item {
  height: 64px;
  line-height: 64px;
}
.drawer .drawer-content {
  background: #001529;
}
.ant-menu-inline-collapsed > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-item .sider-menu-item-img + span,
.ant-menu-submenu-title .sider-menu-item-img + span {
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-top-nav-header-index-head {
  width: 100%;
  -webkit-transition: background 0.3s, width 0.2s;
  transition: background 0.3s, width 0.2s;
  height: 64px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.antd-pro-components-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal {
  line-height: 64px;
  height: 100%;
}
.antd-pro-components-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-submenu-title {
  height: 100%;
}
.antd-pro-components-top-nav-header-index-head.antd-pro-components-top-nav-header-index-light {
  background-color: #fff;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main {
  display: flex;
  height: 64px;
  padding-left: 24px;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main.antd-pro-components-top-nav-header-index-wide {
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main .antd-pro-components-top-nav-header-index-left {
  flex: 1 1;
  display: flex;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main .antd-pro-components-top-nav-header-index-right {
  width: 324px;
}
.antd-pro-components-top-nav-header-index-logo {
  width: 205px;
  height: 64px;
  position: relative;
  line-height: 64px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
}
.antd-pro-components-top-nav-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.antd-pro-components-top-nav-header-index-logo h1 {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  margin: 0 0 0 12px;
  font-weight: 400;
}
.antd-pro-components-top-nav-header-index-light h1 {
  color: #002140;
}
.antd-pro-components-top-nav-header-index-menu {
  border: none;
  height: 64px;
  line-height: 64px;
}

.antd-pro-layouts-header-fixedHeader {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-layouts-basic-layout-content {
  margin: 24px;
  padding-top: 64px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-elements-login-views-login-icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.antd-pro-elements-login-views-login-icon:hover {
  color: #3F51B5;
}
.antd-pro-elements-login-views-login-other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.antd-pro-elements-login-views-login-other .antd-pro-elements-login-views-login-register {
  float: right;
}
.antd-pro-elements-login-views-login-forgot {
  float: right;
}
.antd-pro-elements-login-views-login-button {
  width: 100%;
}
.antd-pro-elements-login-views-login-register {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}
.antd-pro-elements-login-views-login-transition {
  display: none;
  width: 100%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.antd-pro-elements-login-views-login-form {
  width: 100%;
  -webkit-transition: width 0.4s;
  transition: width 0.4s;
  overflow: hidden;
}
.antd-pro-elements-login-views-login-transition1 {
  width: 100%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.antd-pro-elements-login-views-login-form1 {
  width: 100%;
  -webkit-transition: width 0.4s;
  transition: width 0.4s;
  overflow: hidden;
}
.antd-pro-elements-login-views-login-btn {
  width: 100%;
}
.antd-pro-elements-login-views-login-deleteAccountForm {
  margin-bottom: 12px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-elements-register-views-styles-cancel {
  margin-left: 1em;
}
.antd-pro-elements-register-views-styles-inlineForm {
  display: flex;
  flex-wrap: wrap;
}
.antd-pro-elements-register-views-styles-btns {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 1em;
  margin-left: 1em;
}
.antd-pro-elements-register-views-styles-inlineForm {
  display: flex;
  flex-wrap: wrap;
}
.antd-pro-elements-register-views-styles-btns {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 1em;
}

.antd-pro-containers-forgot-password-styles-transition {
  width: 100%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.antd-pro-containers-forgot-password-styles-form {
  width: 100%;
  -webkit-transition: width 0.4s;
  transition: width 0.4s;
  overflow: hidden;
}
.antd-pro-containers-forgot-password-styles-btn {
  width: 100%;
}

.ant-drawer-body {
    padding: 0px !important;
}

.ql-snow .ql-editor h5 {
    font-size: 1.4em;
}

.ant-tabs-bar {
    margin-bottom: 0;
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight !important;
            animation-name: fadeInRight !important;
}

.edited {
    background: #fff1f0;
}

#liveCollection td.ant-table-row-cell-break-word,
#liveCollection th.ant-table-row-cell-break-word {
    padding: 4px 5px !important;
    font-size: 12px;
}

#rmrdTable th {
    padding: 4px 5px !important;
    font-size: 12px;
}

#rmrdTable thead.ant-table-fixed tr {
    height: 54px;
}

#liveCollection th {
    background-color: #f3f3f3;
}

#liveCollection tr:nth-child(2n) {
    background-color: #FAFAFA;
}

span.data-grid-container, span.data-grid-container:focus {
    outline: none;
}

.data-grid-container .data-grid {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    margin: auto;
    height: 70%;
    text-align: center;
}

.data-grid-container .data-grid .cell.updated {
    background-color: rgba(0, 145, 253, 0.16);
    -webkit-transition: background-color 0ms ease;
    transition: background-color 0ms ease;
}

.data-grid-container .data-grid .cell {
    height: 17px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: cell;
    background-color: unset;
    -webkit-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
    vertical-align: middle;
    text-align: center;
    border: 1px solid #DDD;
    padding: 0;
}

.data-grid-container .data-grid .cell.selected {
    border: 1px double rgb(33, 133, 208);
    -webkit-transition: none;
    transition: none;
    box-shadow: inset 0 -100px 0 rgba(33, 133, 208, 0.15);
}

.data-grid-container .data-grid .cell.read-only {
    background: whitesmoke;
    color: #999;
    text-align: center;
}

.data-grid-container .data-grid .cell > .text {
    padding: 2px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.data-grid-container .data-grid .cell > input {
    outline: none !important;
    border: 2px solid rgb(33, 133, 208);
    text-align: center;
    width: 100%;
    height: 21px;
    background: none;
    display: block;
}

.data-grid-container .data-grid .cell {
    vertical-align: bottom;
}

.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
    white-space: normal;
}

.data-grid-container .data-grid.nowrap .cell,
.data-grid-container .data-grid.nowrap .cell.nowrap,
.data-grid-container .data-grid .cell.nowrap,
.data-grid-container .data-grid.wrap .cell.nowrap,
.data-grid-container .data-grid.clip .cell.nowrap {
    white-space: nowrap;
    overflow-x: visible;
}

.data-grid-container .data-grid.clip .cell,
.data-grid-container .data-grid.clip .cell.clip,
.data-grid-container .data-grid .cell.clip,
.data-grid-container .data-grid.wrap .cell.clip,
.data-grid-container .data-grid.nowrap .cell.clip {
    white-space: nowrap;
    overflow-x: hidden;
}

.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
    display: block;
}

.ql-mention-list-item.selected {
    background-color: #d6d6d6;
}

.ql-mention-list-container{
    z-index: 3;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border: 1px solid hsla(0,0%,82.7%,.3);
    border-top: none;
}
div#redHeaderTable{
    background-color: #f1776ddb;
}
div#redHeaderTable .ant-table-body{
    margin:0px;
}
div#redHeaderTable .ant-table-tbody{
    background-color: white;
}
div#greenHeaderTable{
    background-color: #7ab97a;
}
div#greenHeaderTable .ant-table-body{
    margin:0px;
}
div#greenHeaderTable .ant-table-tbody{
    background-color: white;
}


div.updateBtn{
    position: fixed;
    left: 43%;
    z-index: 20000;
    background: white;
    padding: 20px;
    box-shadow: 1px 1px 10px 1px blue;
}


.antd-pro-components-search-styles-modal {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow-x: hidden;
  background-color: rgba(31, 32, 41, 0.75);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 250ms 700ms ease;
  transition: opacity 250ms 700ms ease;
}
.antd-pro-components-search-styles-visible {
  pointer-events: auto;
  opacity: 1;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.antd-pro-components-search-styles-menuListwrap {
  overflow-y: scroll;
  max-height: 300px;
  scrollbar-width: thin;
  list-style-type: none;
  padding: 0px;
}
.antd-pro-components-search-styles-menuListwrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
.antd-pro-components-search-styles-menuListwrap::-webkit-scrollbar {
  width: 3px;
  background-color: #F5F5F5;
}
.antd-pro-components-search-styles-menuListwrap::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}
.antd-pro-components-search-styles-modal__wrap {
  overflow-y: hidden;
  position: relative;
  display: block;
  width: 40vw;
  max-height: 60%;
  padding: 5px 0px;
  min-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #fff;
  align-self: flex-start;
  scrollbar-width: thin;
  opacity: 0;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  -webkit-transition: opacity 250ms 250ms ease, -webkit-transform 300ms 250ms ease;
  transition: opacity 250ms 250ms ease, -webkit-transform 300ms 250ms ease;
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease, -webkit-transform 300ms 250ms ease;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.antd-pro-components-search-styles-visible .antd-pro-components-search-styles-modal__wrap {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: opacity 250ms 300ms ease, -webkit-transform 350ms 300ms ease;
  transition: opacity 250ms 300ms ease, -webkit-transform 350ms 300ms ease;
  transition: opacity 250ms 300ms ease, transform 350ms 300ms ease;
  transition: opacity 250ms 300ms ease, transform 350ms 300ms ease, -webkit-transform 350ms 300ms ease;
}
.antd-pro-components-search-styles-searchBar {
  background-color: white;
  padding: 20px 10px;
  font-size: 22px;
  border: 0px;
}
.antd-pro-components-search-styles-searchBar:focus {
  background-color: white;
  padding: 20px 10px;
  font-size: 22px;
  border: 0px;
  box-shadow: none;
}
.antd-pro-components-search-styles-searchIcon {
  color: gray;
  font-size: 25px;
  align-self: center;
  padding: 0px 5px;
}
.antd-pro-components-search-styles-name {
  color: black;
  font-size: 15px;
}
.antd-pro-components-search-styles-menuItem {
  padding: 7px 15px 0px 15px;
  display: flex;
  align-items: center;
  width: 100%;
}
.antd-pro-components-search-styles-anchrr:focus > div {
  background-color: #d8dbec;
}
.antd-pro-components-search-styles-itemInfoWrap {
  display: flex;
  flex-direction: column;
  margin-left: 17px;
  width: 100%;
}
.antd-pro-components-search-styles-infoText {
  color: gray;
  font-size: 12px;
}
.antd-pro-components-search-styles-farmerInfo {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
.antd-pro-components-search-styles-icon {
  width: 14px;
  margin-right: 10px;
}

