.modal {
  position: fixed;
  // display: block;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow-x: hidden;
  background-color: rgba(31, 32, 41, 0.75);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
}

.visible {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.menuListwrap {
  overflow-y: scroll;
  max-height: 300px;
  scrollbar-width: thin;
  list-style-type: none;
  padding: 0px;
}

.menuListwrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.menuListwrap::-webkit-scrollbar {
  width: 3px;
  background-color: #F5F5F5;
}

.menuListwrap::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

.modal__wrap {
  overflow-y: hidden;
  // overflow-x: hidden;
  position: relative;
  display: block;
  width: 40vw;
  max-height: 60%;
  padding: 5px 0px;
  min-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #fff;
  align-self: flex-start;
  scrollbar-width: thin;
  // padding:15px;
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
  transform: scale(0);
}

.visible .modal__wrap {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms 300ms ease, transform 350ms 300ms ease;
}

.searchBar {
  background-color: white;
  padding: 20px 10px;
  font-size: 22px;
  border: 0px;

}

.searchBar:focus {
  background-color: white;
  padding: 20px 10px;
  font-size: 22px;
  border: 0px;
  box-shadow: none;
}

.searchIcon {
  color: gray;
  font-size: 25px;
  align-self: center;
  padding: 0px 5px;
  // padding:10px
}

.name {
  color: black;
  font-size: 15px;
}

.menuItem {
  padding: 7px 15px 0px 15px;
  display: flex;
  align-items: center;
  width: 100%;


}

.anchrr:focus > div {
  background-color: #d8dbec;
}

.itemInfoWrap {
  display: flex;
  flex-direction: column;
  margin-left: 17px;
  width: 100%
}

.infoText {
  color: gray;
  font-size: 12px;
}

.farmerInfo {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.icon {
  width: 14px;
  margin-right: 10px;
}




@primary-color: #3F51B5;