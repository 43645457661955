/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header {
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
  display: flex;
  justify-content: space-between;
}
.mobileHeader {
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
  display: flex;
  justify-content: space-between;
}
.logo {
  height: 64px;
  line-height: 64px;
  vertical-align: top;
  display: inline-block;
  padding: 0 0 0 24px;
  cursor: pointer;
  font-size: 20px;
}
.logo img {
  display: inline-block;
  vertical-align: middle;
}
.menu :global(.anticon) {
  margin-right: 8px;
}
.menu :global(.ant-dropdown-menu-item) {
  min-width: 160px;
}
.trigger {
  font-size: 20px;
  height: 64px;
  line-height: 0;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  padding: calc((64px - 20px) / 2) 24px;
}
.trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.right {
  float: right;
  height: 100%;
  overflow: hidden;
}
.right .action {
  cursor: pointer;
  padding: 0 12px;
  display: flex;
  transition: all 0.3s;
  height: 100%;
}
.right .action > i {
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.65);
}
.right .action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.right .action:global(.opened) {
  background: rgba(0, 0, 0, 0.025);
}
.right .search {
  padding: 0 12px;
}
.right .search:hover {
  background: transparent;
}
.right .account .avatar {
  margin: calc((64px - 24px) / 2) 0;
  margin-right: 8px;
  color: #3F51B5;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: top;
}
.right .name {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.searchIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.searchIcon {
  color: white;
  font-size: 20px;
}
.searchIcon:hover {
  color: lightgray;
}
@media screen and (max-width: 480px) {
  .right .name {
    color: black;
  }
}
.dark {
  height: 64px;
}
.dark .action {
  color: rgba(255, 255, 255, 0.85);
}
.dark .action > i {
  color: rgba(255, 255, 255, 0.85);
}
.dark .action:hover,
.dark .action:global(.opened) {
  background: #3F51B5;
}
.dark .action :global(.ant-badge) {
  color: rgba(255, 255, 255, 0.85);
}
@media only screen and (max-width: 768px) {
  .header :global(.ant-divider-vertical) {
    vertical-align: unset;
  }
  .header .name {
    display: none;
  }
  .header i.trigger {
    padding: 22px 12px;
  }
  .header .logo {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
  }
  .header .right {
    position: absolute;
    right: 12px;
    top: 0;
    background: #fff;
  }
  .header .right .account .avatar {
    margin-right: 0;
  }
}
.company {
  display: inline;
  font-weight: bold;
  font-size: medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
