.ant-drawer-body {
    padding: 0px !important;
}

.ql-snow .ql-editor h5 {
    font-size: 1.4em;
}

.ant-tabs-bar {
    margin-bottom: 0;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInRight {
    animation-name: fadeInRight !important;
}

.edited {
    background: #fff1f0;
}

#liveCollection td.ant-table-row-cell-break-word,
#liveCollection th.ant-table-row-cell-break-word {
    padding: 4px 5px !important;
    font-size: 12px;
}

#rmrdTable th {
    padding: 4px 5px !important;
    font-size: 12px;
}

#rmrdTable thead.ant-table-fixed tr {
    height: 54px;
}

#liveCollection th {
    background-color: #f3f3f3;
}

#liveCollection tr:nth-child(2n) {
    background-color: #FAFAFA;
}

span.data-grid-container, span.data-grid-container:focus {
    outline: none;
}

.data-grid-container .data-grid {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    margin: auto;
    height: 70%;
    text-align: center;
}

.data-grid-container .data-grid .cell.updated {
    background-color: rgba(0, 145, 253, 0.16);
    transition: background-color 0ms ease;
}

.data-grid-container .data-grid .cell {
    height: 17px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: cell;
    background-color: unset;
    transition: background-color 500ms ease;
    vertical-align: middle;
    text-align: center;
    border: 1px solid #DDD;
    padding: 0;
}

.data-grid-container .data-grid .cell.selected {
    border: 1px double rgb(33, 133, 208);
    transition: none;
    box-shadow: inset 0 -100px 0 rgba(33, 133, 208, 0.15);
}

.data-grid-container .data-grid .cell.read-only {
    background: whitesmoke;
    color: #999;
    text-align: center;
}

.data-grid-container .data-grid .cell > .text {
    padding: 2px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.data-grid-container .data-grid .cell > input {
    outline: none !important;
    border: 2px solid rgb(33, 133, 208);
    text-align: center;
    width: 100%;
    height: 21px;
    background: none;
    display: block;
}

.data-grid-container .data-grid .cell {
    vertical-align: bottom;
}

.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
    white-space: normal;
}

.data-grid-container .data-grid.nowrap .cell,
.data-grid-container .data-grid.nowrap .cell.nowrap,
.data-grid-container .data-grid .cell.nowrap,
.data-grid-container .data-grid.wrap .cell.nowrap,
.data-grid-container .data-grid.clip .cell.nowrap {
    white-space: nowrap;
    overflow-x: visible;
}

.data-grid-container .data-grid.clip .cell,
.data-grid-container .data-grid.clip .cell.clip,
.data-grid-container .data-grid .cell.clip,
.data-grid-container .data-grid.wrap .cell.clip,
.data-grid-container .data-grid.nowrap .cell.clip {
    white-space: nowrap;
    overflow-x: hidden;
}

.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
    display: block;
}

.ql-mention-list-item.selected {
    background-color: #d6d6d6;
}

.ql-mention-list-container{
    z-index: 3;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border: 1px solid hsla(0,0%,82.7%,.3);
    border-top: none;
}
div#redHeaderTable{
    background-color: #f1776ddb;
}
div#redHeaderTable .ant-table-body{
    margin:0px;
}
div#redHeaderTable .ant-table-tbody{
    background-color: white;
}
div#greenHeaderTable{
    background-color: #7ab97a;
}
div#greenHeaderTable .ant-table-body{
    margin:0px;
}
div#greenHeaderTable .ant-table-tbody{
    background-color: white;
}


div.updateBtn{
    position: fixed;
    left: 43%;
    z-index: 20000;
    background: white;
    padding: 20px;
    box-shadow: 1px 1px 10px 1px blue;
}

