/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
h2 {
  color: #3F51B5;
  font-size: 18px;
  padding: 0;
  margin-bottom: 3px;
  margin-top: 6px;
  margin-left: 10px;
}
.wrapper {
  display: flex;
  flex-direction: row;
  padding: 0em;
  height: calc(100vh - 112px);
}
.leftContent {
  flex: 1;
  margin-left: 2em;
}
.liveCollection {
  display: flex;
  flex: 7;
  flex-direction: column;
  overflow: scroll;
  background: white;
  border-radius: 4px;
}
.liveCollection h2 {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
.childData {
  font-size: 10px;
}
.compare {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  background: white;
  border-radius: 4px;
  margin: 10px 0;
  padding: 10px 0 0 10px;
}
.secondCompare {
  width: 50%;
}
.compareChildren {
  border: 1px solid rgba(139, 137, 137, 0.404);
  border-radius: 5px;
  display: flex;
  height: 70px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.heading {
  background-color: #3F51B5;
  border-radius: 3px;
  padding: 0.4em 0.4em 0.4em 0.5em;
  font-size: 1.3em;
  font-weight: 400;
  color: white;
}
.headingDummy {
  background-color: #3F51B5;
  text-align: center;
  border-radius: 3px;
  padding: 0.4em 0.4em 0.4em 0.5em;
  font-size: 1.3em;
  font-weight: 400;
  color: white;
}
.invertHeading {
  background-color: white;
  border-radius: 3px;
  padding: 0.4em 0.4em 0.4em 0.5em;
  font-size: 1.3em;
  font-weight: 600;
  color: #3F51B5;
}
.tableWrapper {
  background-color: white;
  margin-top: 0.5em;
  border-radius: 5px;
  overflow: hidden;
}
.parent {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}
.rightContent {
  flex: 1;
  margin-left: 2em;
  max-width: 300px !important;
}
.headData {
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}
.headDate {
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  font-size: large;
}
.labelSuccess {
  background-color: #5cb85c;
}
.labelInfo {
  background-color: #f0ad4e;
}
.table {
  text-align: right;
  width: 100%;
}
.table th {
  background-color: rgba(128, 128, 128, 0.109);
  border: 1px solid rgba(139, 137, 137, 0.404);
  padding: 0.3em 1em 0.3em 0;
}
.table td {
  border: 1px solid rgba(139, 137, 137, 0.404);
  padding: 0.3em 1em 0.3em 0;
}
.table tr td:nth-child(1) {
  font-size: 12px;
}
.table tr:nth-child(2) td:nth-child(1) {
  font-weight: 500;
  color: #ec0da7;
}
.table tr:nth-child(3) td:nth-child(1) {
  font-weight: 500;
  color: #06CE4A;
}
.table tr:nth-child(4) td:nth-child(1) {
  font-weight: 500;
  color: #BE5FC7;
}
.table tr:nth-child(5) td:nth-child(1) {
  font-weight: 500;
  color: #FFBB0E;
}
.table tr:nth-child(6) td:nth-child(1) {
  font-weight: 500;
  color: #0D9CEC;
}
.childValue {
  font-size: 1em;
  font-weight: bold;
}
.childSubValue {
  font-weight: 600;
  font-size: 1.5em;
}
.compareChild {
  padding: 10px;
  width: 60%;
  color: white;
}
.accepted,
.rejected {
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  margin: 0 10px;
}
.accepted {
  color: #8BC34A;
  border: 1px #8BC34A solid;
  background: #F6FFED;
}
.rejected {
  color: #E21C3D;
  border: 1px #E21C3D solid;
  background: #F9DEDE;
}
.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticker {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  font-size: 14px;
}
.collectionDate {
  padding: 8px;
  text-align: end;
}
@media screen and (max-width: 480px) {
  .wrapper {
    height: inherit;
  }
  .rightContent {
    flex: 1;
    max-width: initial !important;
    margin: 0;
  }
  .leftContent {
    display: none;
  }
}
.date {
  margin-left: 25em;
  font-size: 0.8em;
}
