/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.popover {
  width: 336px;
}
.noticeButton {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s;
}
.icon {
  padding: 4px;
}
.tabs :global .ant-tabs-nav-scroll {
  text-align: center;
}
.tabs :global .ant-tabs-bar {
  margin-bottom: 4px;
}
